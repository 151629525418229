<app-modal #modalComponent>
    <div class="w-full max-w-md lg:px-4 pb-2">
        <h5>
            Seleccione un topping
        </h5>
     
        <div class="mt-2 flex flex-wrap gap-3">
            <div *ngFor="let item of list" (click)="selectedTopping(item)" class="w-auto h-24 border border-dashed p-4 flex flex-col gap-4 items-left justify-start text-sm text-left rounded border-gris-base dark:text-white cursor-pointer" >
                <span >Nombre: {{item.name}}</span>
                <span >Precio: {{item.price}}</span>
            </div>
        </div>
        
    
        <div class="mt-6 lg:mt-10 flex justify-end">
            <button (click)="close()" type="button" class="btn btn-link btn-sm mr-4 uppercase"> {{'buttons.cerrar' | translate}} </button>
            <!-- <button (click)="close()" type="button" class="btn btn-principal">{{'buttons.save' | translate}}</button> -->
        </div>
    </div>
</app-modal>

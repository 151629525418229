import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private id_store: BehaviorSubject<string> = new BehaviorSubject('')

  constructor(
    private httpService : HttpService
  ) { }

  setIdStore(id_store:string){
    localStorage.setItem('id_store_update', id_store)
    this.id_store.next(id_store)
  }

  getIdStore(){
    let id_store_update = localStorage.getItem('id_store_update')
    this.id_store.next(id_store_update)
    return this.id_store
  }

  updateUser(data): Promise<any>{
    return this.httpService.post('admin/users/changePassword', data)
  }
}

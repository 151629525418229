import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class FiltrosService {

  private reloadPage: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private filtros: BehaviorSubject<any> = new BehaviorSubject({});


  private is_editing: BehaviorSubject<any> = new BehaviorSubject(false);
  private first: BehaviorSubject<any> = new BehaviorSubject(true);
  private dataProperty: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(
    private httpService: HttpService,
  ) { }


  setProperty(data){
    this.dataProperty.next(data)
  }

  getProperty(): Observable<any>{
    return this.dataProperty
  }


  setEdit(data){
    this.is_editing.next(data)
  }

  getEdit(): Observable<any>{
    return this.is_editing
  }

  setFirstTime(data){
    this.first.next(data)
  }

  getFirstTime(): Observable<any>{
    return this.first
  }

  setFiltros(filtros){
    this.filtros.next(filtros)
  }

  getFiltros(){
    return this.filtros
  }

  getListPage(page: number = 1, status: string = "", search: string = "") : Promise<any> {
    let params : HttpParams = new HttpParams()
    .append('page', String(page))
    .append('status', status)
    .append('search', search)
    return this.httpService.get(`admin/filters/list`, params)
  }

  getListPageAdmin(page: number = 1, status: string = "", search: string = "") : Promise<any> {
    let params : HttpParams = new HttpParams()
    .append('page', String(page))
    .append('status', status)
    .append('search', search);

    return this.httpService.get(`admin/filters-admin/list`, params)
  }


  setReload(){
    this.reloadPage.next(true)
  }

  getReload(): Observable<any>{
    return this.reloadPage
  }


  getFullListPage() : Promise<any> {
    return this.httpService.get(`admin/filters/fullList`)
  }

  getFullListPageAdmin(): Promise<any>{
    return this.httpService.get(`admin/filters-admin/fullList`)
  }

  getSimpleFullListPage() : Promise<any> {
    return this.httpService.get(`admin/filters/simplefullList`)
  }

  getSimpleFullListPageAdmin(): Promise<any>{
    return this.httpService.get(`admin/filters-admin/simplefullList`)
  }

  getProductBySku(sku: string ) : Promise<any> {
    return this.httpService.get(`admin/filters/${sku}/detail`);
  }

  getProductBySkuAdmin(sku: string ) : Promise<any> {
    return this.httpService.get(`admin/filters-admin/${sku}/detail`);
  }

  updateStatusById(id: string, status: string) : Promise<any> {
    return this.httpService.put(`admin/filters/${id}/status`, {status: status});
  }

  updateStatusByIdAdmin(id: string, status: string) : Promise<any> {
    return this.httpService.put(`admin/filters-admin/${id}/status`, {status: status});
  }

  add(filters: object) : Promise<any> {
    return this.httpService.post(`admin/filters`, filters);
    // return this.httpService.put(`admin/products/sku/filters`, filters);
  }
  remove(productSku:string, filters_id:string) : Promise<any> {
    return this.httpService.put(`admin/products/${productSku}/filters/${filters_id}`);
  }

  addAdmin(filters: object): Promise<any> {
    return this.httpService.post('admin/filters-admin', filters)
  }

  update(id:string, data:object){
    return this.httpService.post(`admin/filters/${id}/update`, data)
  }

  updateAdmin(id:string, data:object){
    return this.httpService.post(`admin/filters-admin/${id}/update`, data)
  }

  deleteCategory(id:string, option:string): Promise<any>{
    return this.httpService.delete(`admin/filters/${id}/${option}`)
  }

  removeCtgFromFather(ctgFather:string, ctgSon:string): Promise<any>{
    return this.httpService.post(`admin/filters/${ctgFather}/remove/${ctgSon}`)
  }

}
